<template>
  <div id="home-fuel">
    <v-container class="bv-example-row">
      <v-row class="mt-2">
        <v-col class="btn-group" cols="6">
          <router-link :to="'/home'" tag="div">
            <div class="btn-circle">
              <div class="picto-img picto-evCharging"></div>
            </div>
          </router-link>
          <span class="text-h6">{{ $t("EVCharging") }}</span>
        </v-col>
        <v-col class="btn-group" cols="6">
          <router-link :to="'/store'" tag="div">
            <div class="btn-circle">
              <div class="picto-img picto-evChargingRetail"></div>
            </div>
          </router-link>
          <span class="text-h6">{{ $t("EVChargingRetail") }}</span>
        </v-col>
        <v-col class="btn-group" cols="6">
          <div class="btn-circle">
            <div class="picto-img picto-fuel"></div>
          </div>
          <span class="text-h6">{{ $t("fuel") }}</span>
        </v-col>
        <v-col class="btn-group" cols="6">
          <div class="btn-circle">
            <div class="picto-img picto-car"></div>
          </div>
          <span class="text-h6">{{ $t("CarWash") }}</span>
        </v-col>
        <v-col class="btn-group" cols="6">
          <div @click="goToClickAndCollect()" class="btn-circle">
            <div class="picto-img picto-clickCollect"></div>
          </div>
          <span class="text-h6">{{ $t("Click&collect") }}</span>
        </v-col>
        <v-col @click="goToScanAndPay()" class="btn-group" cols="6">
          <div class="btn-circle">
            <div class="picto-img picto-scanAndPay"></div>
          </div>
          <span class="text-h6">{{ $t("Scan&Pay") }}</span>
        </v-col>
        <v-col class="btn-group" cols="6">
          <div class="btn-circle">
            <div class="picto-img picto-carPark"></div>
          </div>
          <span class="text-h6">{{ $t("Parking ") }}</span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'Menu',
  data(){
    return {
      map: null,
      page: {
          title: "menuPageTitle",
          description: null
      },
    }
  },
  methods: {
    goToScanAndPay(){
      window.open("https://wl-dsp-scan-and-pay.pubqlf.co.as8677.net/", '_blank').focus()
    },
    goToClickAndCollect(){
      window.open("https://wl-dsp-click-and-collect.pubqlf.co.as8677.net/", '_blank').focus()
    }
  },
}
</script>
<style>
@import "../assets/css/menu.css";
</style>
